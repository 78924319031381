<template>
  <div class="sale-detail">
    <app-detail :opt="opt"></app-detail>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "查看详情",
        form: [
          {
            label: "统计周期",
            key: "turn",
            isCol50: true
          },
          {
            label: "异常订单数",
            key: "exceptionOrderSum",
            isCol50: true
          },
          {
            label: "日期",
            key: "statDate",
            isCol50: true
          },
          {
            label: "异常订单金额",
            key: "exceptionOrderFee",
            isCol50: true
          },
          {
            label: "订单总数",
            key: "totalOrder",
          },
          {
            label: "订单总金额(元)",
            key: "orderTotalFee",
          },
          {
            label: "应到账金额(元)",
            key: "oughtTotalFee",
          },
          {
            label: "实际到账金额(元)",
            key: "actualTotalFee",
          }
        ],
        get(opt) {
          var data = _this.$route.query;
          opt.cb({
            turn: '按日',
            exceptionOrderSum: data.exceptionOrderSum,
            statDate: data.statDate,
            exceptionOrderFee:data.exceptionOrderFee,
            totalOrder: data.totalOrder,
            orderTotalFee: data.orderTotalFee,
            oughtTotalFee: data.oughtTotalFee,
            oughtTotalFee: data.oughtTotalFee,
            actualTotalFee: data.actualTotalFee
          });
        }
      }
    };
  },
  methods: {
  
  },
  mounted: function() {
    console.log("sale-detail created!!");
    console.log(this.$route.query)
  }
};
</script>

